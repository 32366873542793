jQuery(document).ready(function($){
	if( $('#wpcf7-f26-o1').length ){
		var form = document.querySelector('#wpcf7-f26-o1');
		 
		form.addEventListener( 'wpcf7mailsent', function( event ) {
		    var html = '<div class="success_msg">Thank you for your message we will contact you shortly</div>';

		    $('#wpcf7-f26-o1').hide();
		    $('#contact_form_wrap').append( html );
		}, false );
	}

});

jQuery(document).ready(function($){
	function toggle() {
		$('#header_nav').toggleClass('active');
		$('#menubtn').toggleClass('btnactive');
	}
	$( ".menubtn" ).on( "click", toggle );
});

jQuery(document).ready(function($){
	$('.logoslider').slick({
	  infinite: true,
	  slidesToShow: 5,
	  slidesToScroll: 1,
      arrows: false,
      autoplay: true,
	  autoplaySpeed: 2000,
	  responsive: [
	    {
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 3,
	        slidesToScroll: 1,
	      }
	    },
	    {
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2
	      }
	    }
	  ]

	});
	AOS.init();
});